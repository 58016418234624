import React, { FC, Suspense, useEffect, useState } from 'react'

import { useFlags } from 'launchdarkly-react-client-sdk'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'

import Header from 'components/atoms/Header'
import InfromationBlock from 'components/shared/components/InfromationBlock'
import { useCompanyData, useResizeDevice } from 'context'
import { useAuth } from 'context/auth'
import { useMsTeams } from 'context/msTeams'
import { useAppSelector } from 'hooks'
import { AUTH_ROUTES, MANAGER_ROUTES, ROUTES } from 'types/routes'
import { EUserGroup } from 'types/user.types'

import { WrapperBackground, WrapperMain, WrapperRoot } from './styles'
import Sidebar from '../Sidebar'

/* containers */
const Employees = React.lazy(() => import('containers/Employees'))
const Settings = React.lazy(() => import('containers/Settings'))
const RoomsPage = React.lazy(() => import('containers/RoomsPage'))
const RoomsPageV2 = React.lazy(() => import('containers/RoomsPageV2'))
const ResourcePage = React.lazy(() => import('containers/Resources'))
const Profile = React.lazy(() => import('containers/Profile'))
const Dashboard = React.lazy(() => import('containers/Dashboard'))
const DashboardForTeamLead = React.lazy(() => import('containers/DashboardForTeamLead'))
const Reports = React.lazy(() => import('containers/Reports'))
const Subscription = React.lazy(() => import('containers/Subscription'))
const OfficeBuilder = React.lazy(() => import('containers/OfficeBuilder'))
const SpaceScreen = React.lazy(() => import('containers/SpaceScreen'))
const Calendar = React.lazy(() => import('containers/Calendar'))
const MobileRooms = React.lazy(() => import('containers/MobileRooms'))
const InitialSetup = React.lazy(() => import('containers/InitialSetup'))
const AdminPanel = React.lazy(() => import('containers/AdminPanel'))

const routesWithCustomHeader = [
    ROUTES.CALENDAR,
    ROUTES.ROOMS,
    ROUTES.RESOURCES,
    ROUTES.SETTINGS,
    ROUTES.PROFILE,
    ROUTES.SPACE_SCREEN,
    ROUTES.SCHEDULE,
    MANAGER_ROUTES.OFFICES,
    MANAGER_ROUTES.EMPLOYEES,
    MANAGER_ROUTES.ADMIN_PANEL,
    MANAGER_ROUTES.DASHBOARD,
]

const Wrapper: FC = () => {
    const { pathname, state } = useLocation()
    const { roomManagement, useOnlySpaceScreen } = useFlags()
    const { userGroup, signOut, userAttributes } = useAuth()
    const { company } = useCompanyData()
    const { update, hide } = useIntercom()

    const { isMobile, height } = useResizeDevice()
    const { inTeams } = useMsTeams()

    const [wrapperContainer, setWrapperContainer] = useState<HTMLDivElement | null>(null)

    const isManager = userGroup === EUserGroup.MANAGER
    const isEmployee = userGroup === EUserGroup.EMPLOYEE
    const isTeamLead = userGroup === EUserGroup.TEAM_LEAD

    const hasCustomHeader = routesWithCustomHeader.includes(('/' + pathname.split('/')[1]) as ROUTES)

    const {
        email: userEmail,
        firstname: userName,
        turnOnExperimentalFeatures,
    } = useAppSelector((rootState) => rootState.user)

    let Rooms = !isMobile ? RoomsPage : MobileRooms
    if (turnOnExperimentalFeatures && !isMobile) {
        Rooms = RoomsPageV2
    }

    useEffect(() => {
        if (company?.id && company?.title) {
            update({
                email: userEmail,
                name: userName,
                company: {
                    companyId: company.id,
                    name: company.title,
                },
            })
        }
    }, [company?.id, company?.title, userName, userEmail])

    useEffect(() => {
        if (wrapperContainer) {
            const vh = height * 0.01
            wrapperContainer.style.setProperty('--vh', `${vh}px`)
        }
    }, [height, wrapperContainer])

    if (pathname === ROUTES.INITIAL_SETUP) {
        return <InitialSetup />
    }

    let mainPage = ROUTES.CALENDAR

    if (roomManagement) {
        mainPage = ROUTES.ROOMS
    }

    if (useOnlySpaceScreen) {
        mainPage = ROUTES.SPACE_SCREEN
    }

    return (
        <>
            <WrapperBackground isMobile={isMobile} />

            <WrapperRoot onClick={hide} ref={setWrapperContainer}>
                {!inTeams && <Sidebar pathname={pathname} userGroup={userGroup} />}
                {!hasCustomHeader && <Header />}
                <WrapperMain hasCustomHeader={!hasCustomHeader} hasSidebar={!inTeams} isMobile={isMobile}>
                    <Suspense fallback={null}>
                        {isTeamLead && (
                            <Routes>
                                {isMobile ? (
                                    <Route
                                        path={`${MANAGER_ROUTES.DASHBOARD}/*`}
                                        element={<Navigate to={ROUTES.CALENDAR} />}
                                    />
                                ) : (
                                    <Route path={`${MANAGER_ROUTES.DASHBOARD}/*`} element={<DashboardForTeamLead />} />
                                )}
                                <Route path={`${ROUTES.CALENDAR}/*`} element={<Calendar />} />
                                <Route path={`${ROUTES.SPACE_SCREEN}/*`} element={<SpaceScreen />} />
                                <Route path={ROUTES.PROFILE} element={<Profile />} />
                                <Route path={`${ROUTES.ROOMS}/*`} element={<Rooms />} />
                                <Route path={`${ROUTES.RESOURCES}/*`} element={<ResourcePage />} />
                                <Route path={`${MANAGER_ROUTES.EMPLOYEES}/*`} element={<Employees />} />
                                {pathname !== AUTH_ROUTES.LOGOUT && (
                                    <Route path="*" element={<Navigate to={mainPage} />} />
                                )}
                            </Routes>
                        )}
                        {isEmployee && (
                            <Routes>
                                <Route path={`${ROUTES.CALENDAR}/*`} element={<Calendar />} />
                                <Route path={`${ROUTES.SPACE_SCREEN}/*`} element={<SpaceScreen />} />
                                <Route path={ROUTES.PROFILE} element={<Profile />} />
                                <Route path={`${ROUTES.ROOMS}/*`} element={<Rooms />} />
                                {pathname !== AUTH_ROUTES.LOGOUT && (
                                    <Route path="*" element={<Navigate to={mainPage} />} />
                                )}
                            </Routes>
                        )}
                        {isManager && (
                            <Routes>
                                {isMobile ? (
                                    <Route
                                        path={`${MANAGER_ROUTES.DASHBOARD}/*`}
                                        element={<Navigate to={ROUTES.CALENDAR} />}
                                    />
                                ) : (
                                    <Route path={`${MANAGER_ROUTES.DASHBOARD}/*`} element={<Dashboard />} />
                                )}
                                <Route path={`${MANAGER_ROUTES.EMPLOYEES}/*`} element={<Employees />} />
                                <Route path={MANAGER_ROUTES.OFFICES} element={<OfficeBuilder />} />
                                <Route path={`${ROUTES.SETTINGS}/*`} element={<Settings />} />
                                <Route path={`${ROUTES.CALENDAR}/*`} element={<Calendar />} />
                                <Route path={`${ROUTES.SPACE_SCREEN}/*`} element={<SpaceScreen />} />
                                <Route path={ROUTES.PROFILE} element={<Profile />} />
                                <Route path={MANAGER_ROUTES.REPORTS} element={<Reports />} />
                                <Route path={MANAGER_ROUTES.SUBSCRIPTIONS} element={<Subscription />} />
                                <Route path={`${ROUTES.ROOMS}/*`} element={<Rooms />} />
                                <Route path={`${ROUTES.RESOURCES}/*`} element={<ResourcePage />} />
                                <Route path={`${MANAGER_ROUTES.ADMIN_PANEL}/*`} element={<AdminPanel />} />
                                {state && <Route element={<Navigate to={state} />} />}
                                {pathname !== AUTH_ROUTES.LOGOUT && (
                                    <Route path="*" element={<Navigate to={mainPage} />} />
                                )}
                            </Routes>
                        )}
                    </Suspense>
                </WrapperMain>
                {company && <InfromationBlock isManager={isManager} company={company} />}
            </WrapperRoot>
        </>
    )
}

export default Wrapper
